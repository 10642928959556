import { createRouter, createWebHistory } from 'vue-router'
import ReservationForm from '../views/ReservationForm.vue'
import Reservation from '../views/Reservation.vue'

const routes = [
  {
    path: '/:reservation_form_url/reservation/:reservation_token',
    name: 'Reservation',
    component: Reservation,
    props: true,
  },
  {
    path: '/:reservation_url',
    name: 'ReservationForm',
    component: ReservationForm,
    props: true,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
