<template>
  <div class="text-center py-4">
    <button data-bs-toggle="modal" data-bs-target="#languageSelectorModal" class="btn btn-white" style="height:41px; padding:5px">
      <img :src="require(`@/assets/images/flags/${$i18n.locale}.svg`)" style="max-height: 32px" />
      Change language
    </button>
  </div>
  <div class="modal fade" id="languageSelectorModal" tabindex="-1" aria-labelledby="languageSelectorModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="languageSelectorModalLabel">Select your language</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" role="alert" v-if="ajax_error">
            {{ $t("no_internet_connection") }}
          </div>
          <div class="text-center m-auto mt-4">
            <div
              v-for="language in availableLanguages"
              :key="'btn-language-selector-' + language"
              class="px-4">
              <a
                class="btn btn-block text-start"
                :class="($i18n.locale == language) ? 'btn-orange' : 'btn-white'"
                role="button"
                @click="$i18n.locale = language"
              >
                <img :src="require(`@/assets/images/flags/${language}.svg`)" style="max-height: 32px" />
                {{ $i18n.messages[language].language_name }}
              </a>
              <br>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("close_button") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  props: ['reservationClientData', 'reservationBusinessData'],
  data () {
    return {
      availableLanguages: ['it_it', 'en_gb'],
    }
  },
}
</script>

<style scoped>
.btn {
  margin: .3rem;
  padding: 10px 10px;
  border-radius: 50px;
  border: 0;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px;
  cursor: pointer;
}
.btn-white {
  background-color: #FFF;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.4);
  color: #000;
}
.btn-orange {
  background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
  box-shadow: 0px 10px 15px rgba(252, 56, 56, 0.4);
  color: #fff;
}
.btn-orange:hover {
  color: #fff;
}
</style>