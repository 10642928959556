<template>
  <div class="container justify-content-center">
    <div class="card shadow border-0 w-100 mb-3">
      <div class="card-body">
        <div class="card-text">
          <div class="alert alert-success mt-4" v-html="$t('reservation_summary.thank_you_text')"></div>
          <div class="alert alert-info mt-4">
            <p class="font-weight-bold">{{ $t('reservation_summary.title') }}</p>
            {{ $t('reservation_summary.date') }}: {{ formatDate(reservationClientData.date) }}<br />
            {{ $t('reservation_summary.time') }}: {{ reservationClientData.time }}<br />
            {{ $t('reservation_summary.number_of_people') }}: {{ reservationClientData.number_of_people }}<br />
            {{ $t('reservation_summary.name') }}: {{ reservationClientData.name }}<br />
            {{ $t('reservation_summary.email') }}: {{ reservationClientData.email }}<br />
            {{ $t('reservation_summary.telephone') }}: +{{ reservationClientData.telephone_prefix }} {{ reservationClientData.telephone_number }}<br />

            <hr />
            {{ $t('reservation_summary.in_case_of_problems_text') }}
            <div class="text-center" v-if="reservationBusinessData.telephone_readable">
              <a color="primary" class="btn btn-blue px-4" :href="'tel:'+reservationBusinessData.telephone_link">
                <MDBIcon icon="phone-alt" iconStyle="fas" />
                {{ reservationBusinessData.telephone_readable }}
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReservationSummary',
  props: ['reservationClientData', 'reservationBusinessData'],
  methods: {
    formatDate: function(date) {
      // TODO: add full month
      date = date.slice(0, 10).split("-")
      return `${date[2]}/${date[1]}/${date[0]}`;
    }
  }
}
</script>

<style scoped>
.btn {
  margin: .3rem;
  padding: 10px 10px;
  border-radius: 50px;
  border: 0;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px;
  cursor: pointer;
}
.btn-blue {
  background-image: linear-gradient(147deg, #0071ce 0%, #10009e 74%);
  box-shadow: 0px 10px 15px rgba(13, 0, 133, 0.4);
  color: #fff;
}
.btn-blue:hover {
  color: #fff;
}
</style>