<template>
  <div v-if="reservationBusinessData.date_not_available_behaviour == 1" class="alert alert-info mt-4" id="section-date_not_available_error">
    {{ $t('reservation_form.date_not_reservable_error_contact_us') }}
    <div class="text-center" v-if="reservationBusinessData.telephone_readable">
      <a color="primary" class="btn btn-blue px-4" :href="'tel:'+reservationBusinessData.telephone_link"><MDBIcon icon="phone-alt" iconStyle="fas" /> {{ reservationBusinessData.telephone_readable }} </a>
    </div>
  </div>
  <div v-else-if="reservationBusinessData.date_not_available_behaviour == 2" class="alert alert-warning mt-4" id="section-date_not_available_error">
    {{ $t('reservation_form.date_not_reservable_error_no_contact') }}
  </div>
</template>

<script>
export default {
  name: 'AlertDateNotReservable',
  props: ['reservationBusinessData'],
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:500");
.btn {
  margin: .3rem;
  padding: 10px 10px;
  border-radius: 50px;
  border: 0;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px;
  cursor: pointer;
}
.btn:disabled {
  background-image: linear-gradient(147deg, #494949 0%, #727272 74%);
  box-shadow: 0px 10px 15px rgba(56, 56, 56, 0.4);
  cursor: not-allowed;
  color: #FFF;
}
.btn-orange {
  background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
  box-shadow: 0px 10px 15px rgba(252, 56, 56, 0.4);
  color: #fff;
}
.btn-orange:hover {
  color: #fff;
}
.btn-white {
  background-color: #FFF;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.4);
  color: #000;
}
.btn-blue {
  background-image: linear-gradient(147deg, #0071ce 0%, #10009e 74%);
  box-shadow: 0px 10px 15px rgba(13, 0, 133, 0.4);
  color: #fff;
}
.btn-blue:hover {
  color: #fff;
}

h5 {
  font-weight: 700;
}
</style>