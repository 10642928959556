<template>
  <router-view />

  <!--Footer-->
  <footer class="text-lg-start">

    <!-- Copyright -->
    <div class="text-center p-3">
      <a class="footer-logo" href="https://easymenu.cloud/">Powered by <img src="@/assets/EasyMenu.cloud-header-blue.svg" /></a>
    </div>
    <!-- Copyright -->
  </footer>
  <!--Footer-->
</template>

<script>
import VueCookies from "vue-cookies";
export default {
  name: 'App',
  watch: {
    '$i18n.locale': function(newLang) {
      VueCookies.set("language", newLang, "1y");
    }
  },
}
</script>

<style>
#app {
  font-family: Poppins, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.footer-logo {
  max-width: 150px;
  opacity: 0.4;
  text-decoration: none;
  transition:.5s;
}
.footer-logo:hover {
  opacity: 1;
  transition:.5s;
}
.footer-logo img {
  max-width: 150px;
}
</style>
