<template>
  <Header v-if="reservationBusinessData" :reservationBusinessData="reservationBusinessData" />
  <header v-else>
    <div class="p-5 text-center" v-if="show_loading">
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </header>
  <!--Main Navigation-->

  <!--Main layout-->
  <LanguageSelector />
  <main v-if="reservationBusinessData">
      
    <div class="container justify-content-center">
      <div v-if="Object.keys(reservationBusinessData.availability).length == 0" class="alert alert-info mt-4">
        {{ $t('reservation_form.no_availability_error') }}
        <div class="text-center" v-if="reservationBusinessData.telephone_readable">
          <a color="primary" class="btn btn-blue px-4" :href="'tel:'+reservationBusinessData.telephone_link"><MDBIcon icon="phone-alt" iconStyle="fas" /> {{ reservationBusinessData.telephone_readable }} </a>
        </div>
      </div>

      <div v-else-if="!showDateTimeInOneCard" class="card shadow border-0 w-100 mb-3">
        <div class="card-body">
          <h5 class="card-title">{{ $t('reservation_form.date_title') }}</h5>
          <div class="card-text">
            <template v-for="[i, date] of Object.keys(reservationBusinessData.availability).entries()" :key="'date-btn-'+date">
              <button
                v-if="i < MAX_DATES_ONLOAD || select_another_date"
                @click="reservationClientData.date = date; dateNotReservableError = false; reservationClientData.time = null;
                  scrollToSection('time')"
                type="button"
                class="btn"
                :class="(reservationClientData.date == date) ? 'btn-orange' : 'btn-white'"
              >
                {{ (date == today_date) ? $t('reservation_form.date_today') : '' }}
                {{ (date == tomorrow_date) ? $t('reservation_form.date_tomorrow') : '' }}
                {{ (new Date(date+" 00:00")).getDate() }}
                {{ (new Date(date+" 00:00")).toLocaleString('default', { month: 'long' }) }}
              </button>
            </template>
            <button
              v-if="!select_another_date && Object.keys(reservationBusinessData.availability).length > MAX_DATES_ONLOAD"    
              @click="select_another_date = true; dateNotReservableError = false;"
              type="button"
              class="btn btn-white"
            >{{ $t('reservation_form.date_another_day') }}</button>
            <button
              v-else-if="reservationBusinessData.date_not_available_behaviour == 1 || reservationBusinessData.date_not_available_behaviour == 2"
              @click="dateNotReservableError = true; reservationClientData.date = null; scrollToSection('date_not_available_error')"
              type="button"
              class="btn "
              :class="(dateNotReservableError) ? 'btn-orange' : 'btn-white'"
            >{{ $t('reservation_form.date_another_day') }}</button>

            <AlertDateNotReservable v-if="dateNotReservableError" :reservationBusinessData="reservationBusinessData" />
          </div>
        </div>
      </div>
            
      <!-- Select time -->
      <template v-if="reservationClientData.date">
        <div v-if="!showDateTimeInOneCard" class="card shadow border-0 w-100 mb-3" id="section-time">
          <div class="card-body">
            <h5 class="card-title">{{ $t('reservation_form.time_title') }}</h5>
            <div class="card-text">
              <button
                v-for="time in Object.keys(reservationBusinessData.availability[reservationClientData.date])"
                :key="'time-btn-'+time"
                @click="reservationClientData.time = time;
                  reservationClientData.number_of_people = null;
                  scrollToSection('number_of_people')"
                type="button"
                class="btn"
                :class="(reservationClientData.time == time) ? 'btn-orange' : 'btn-white'"
              >{{ time }}</button>
            </div>
          </div>
        </div>
        <div v-if="showDateTimeInOneCard" class="card shadow border-0 w-100 mb-3" id="section-time">
          <div class="card-body">
            <div class="card-text">
              <button
                type="button"
                class="btn btn-orange"
              >
                {{ (new Date(reservationClientData.date+" 00:00")).getDate() }}
                {{ (new Date(reservationClientData.date+" 00:00")).toLocaleString('default', { month: 'long' }) }}
              </button>
              <button
                type="button"
                class="btn btn-orange"
              >
                {{ reservationClientData.time }}
              </button>
            </div>
          </div>
        </div>
              
      <!-- Select number of people -->
      <template v-if="reservationClientData.time">
        <div class="card shadow border-0 w-100 mb-3" id="section-number_of_people">
          <div class="card-body">
            <h5 class="card-title">{{ $t('reservation_form.number_of_people_title') }}</h5>
            <div class="card-text">
              <template
                v-for="number_of_people in [...Array(reservationBusinessData.availability[reservationClientData.date][reservationClientData.time]).keys()].map(x=>x+1)"
                :key="'number_of_people-btn-'+number_of_people"
              >
                <button
                  v-if="number_of_people <= MAX_NUMBER_OF_PEOPLE_ONLOAD || select_another_number_of_people"
                  @click="reservationClientData.number_of_people = number_of_people;
                    scrollToSection('contact_details')"
                  type="button"
                  class="btn px-4"
                  :class="(reservationClientData.number_of_people == number_of_people) ? 'btn-orange' : 'btn-white'"
                >{{ number_of_people }}</button>
              </template>
              <button
                v-if="!select_another_number_of_people && reservationBusinessData.availability[reservationClientData.date][reservationClientData.time] > MAX_NUMBER_OF_PEOPLE_ONLOAD"    
                @click="select_another_number_of_people = true; dateNotReservableError = false;"
                type="button"
                class="btn btn-white"
              >{{ $t('reservation_form.number_of_people_n_or_more', { n: MAX_NUMBER_OF_PEOPLE_ONLOAD + 1 }) }}</button>
              <button
                v-else
                @click="reservationClientData.number_of_people = reservationBusinessData.availability[reservationClientData.date][reservationClientData.time] + 1; scrollToSection('too_many_people_error')"
                type="button"
                class="btn"
                :class="(reservationClientData.number_of_people > reservationBusinessData.availability[reservationClientData.date][reservationClientData.time]) ? 'btn-orange' : 'btn-white'"
              >{{ $t('reservation_form.number_of_people_n_or_more', { n: reservationBusinessData.availability[reservationClientData.date][reservationClientData.time] + 1 }) }}</button>
              </div>
              <div v-if="!isNumberOfPeopleOk" class="alert alert-info mt-4" id="section-too_many_people_error">
                {{ $t('reservation_form.too_many_people_error', { n: reservationBusinessData.availability[reservationClientData.date][reservationClientData.time] + 1 }) }}
                <div class="text-center" v-if="reservationBusinessData.telephone_readable">
                  <a color="primary" class="btn btn-blue px-4" :href="'tel:'+reservationBusinessData.telephone_link"><MDBIcon icon="phone-alt" iconStyle="fas" /> {{ reservationBusinessData.telephone_readable }} </a>
                </div>
              </div>
            </div>
          </div>
    
          <!-- Select number of people -->
          <template v-if="reservationClientData.number_of_people">

            <!-- If selected number of people can make a reservation online -->
            <template v-if="isNumberOfPeopleOk">
                        
              <div class="card shadow border-0 w-100 mb-3" id="section-contact_details">
                <div class="card-body">
                  <h5 class="card-title">{{ $t('reservation_form.contact_info_title') }}</h5>
                  <div class="card-text">
                    <form @submit="doReservation">
                      <div class="row mb-3">
                        <label for="inputName" class="col-sm-2 col-form-label">{{ $t('reservation_form.contact_info_name') }}</label>
                        <div class="col-sm-10">
                          <input type="text" autocomplete="name" class="form-control" id="inputName" v-model="reservationClientData.name" required>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <label for="inputEmail" class="col-sm-2 col-form-label">{{ $t('reservation_form.contact_info_email') }}</label>
                        <div class="col-sm-10">
                          <input type="email" autocomplete="email" class="form-control" id="inputEmail" v-model="reservationClientData.email" required>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <label for="inputTelephone" class="col-sm-2 col-form-label">{{ $t('reservation_form.contact_info_telephone') }}</label>
                        <div class="col-sm-10">
                          <div class="input-group mb-3">
                            <span class="input-group-text">+</span>
                            <input type="text" autocomplete="tel-country-code" class="form-control" style="max-width:80px" v-model="reservationClientData.telephone_prefix">
                            <input type="text" autocomplete="tel-national" id="inputTelephone" name="telephone" class="form-control" v-model="reservationClientData.telephone_number">
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <label for="inputNotes" class="col-sm-2 col-form-label">{{ $t('reservation_form.contact_info_notes') }}</label>
                        <div class="col-sm-10">
                          <textarea class="form-control" id="inputNotes" v-model="reservationClientData.notes"></textarea>
                        </div>
                      </div>
                      <div v-if="reservationBusinessData.ask_for_marketing_consent" class="row mb-3">
                        <div class="col-12">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="reservationClientData.marketing_consent"
                              id="inputMarketingConsent"
                            />
                            <label class="form-check-label" for="inputMarketingConsent">
                              {{ $t('reservation_form.contact_marketing_consent') }}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="alert alert-danger mt-4" v-if="reservationError">
                        <p v-html="$t('reservation_form.generic_error')"></p>
                        <div class="text-center" v-if="reservationBusinessData.telephone_readable">
                          <a color="primary" class="btn btn-blue px-4" :href="'tel:'+reservationBusinessData.telephone_link"><MDBIcon icon="phone-alt" iconStyle="fas" /> {{ reservationBusinessData.telephone_readable }} </a>
                        </div>
                      </div>
                      <div class="text-center">
                        <button type="submit" class="btn btn-blue px-4 mt-3" :disabled="doReservationLoading">
                        <span
                          class="spinner-border spinner-border-sm me-4"
                          role="status"
                          aria-hidden="true"
                          v-if="doReservationLoading"
                        ></span> {{ $t('reservation_form.reserve_button') }}</button>
                      </div>
                      <p class="mt-3 text-black-50" v-html="$t('reservation_form.privacy_policy')"></p>
                    </form>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </template>
      </template>
    </div>
  </main>
  <main v-else>
    <div class="container justify-content-center p-5">
        <div class="alert alert-danger" role="alert" v-if="ajax_error">
          {{ $t("no_internet_connection") }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="reservationNotFoundError">
          {{ $t("menu_not_found") }}
      </div>
    </div>
  </main>
  <!--Main layout-->
</template>

<script>
import { useRoute } from 'vue-router'
import axios from 'axios';
import Header from '../components/Header.vue'
import LanguageSelector from '../components/LanguageSelector.vue'
import AlertDateNotReservable from '../components/AlertDateNotReservable.vue'

export default {
  name: 'ReservationForm',
  components: {
    Header,
    LanguageSelector,
    AlertDateNotReservable,
  },
  data () {
    return {
      MAX_DATES_ONLOAD: 1, // number of dates that are shown to the user on page load
      MAX_NUMBER_OF_PEOPLE_ONLOAD: 2, // number of options that are shown to the user on page load
      select_another_date: false,
      select_another_number_of_people: false,

      languageSelectorModalShow: false,
      availableLanguages: [],

      show_loading: true,
      ajax_error: false,
      dateNotReservableError: false,
      reservationNotFoundError: false,
      reservationError: false,

      reservationClientData: {
        date: null,
        time: null,
        number_of_people: null,

        name: '',
        email: '',
        telephone_prefix: '39',
        telephone_number: null,
        notes: '',

        marketing_consent: false,
      },
      doReservationLoading: false,
      
      reservationBusinessData: null,

      today_date: null,
      tomorrow_date: null,

      showDateTimeInOneCard: false,
    }
  },
  computed: {
    isNumberOfPeopleOk: function () {
      return this.reservationClientData.number_of_people <= this.reservationBusinessData.availability[this.reservationClientData.date][this.reservationClientData.time];
    }
  },
  mounted () {
    const route = useRoute();
    const reservationUrl = route.params['reservation_url'].toUpperCase();
    this.fetchReservationForm(reservationUrl).then(() => {
        console.log("nono", this.reservationBusinessData.date_not_available_behaviour)
      // Do not show "other date" button
      if (this.reservationBusinessData.date_not_available_behaviour == 3) {
        let availabilityDates = Object.keys(this.reservationBusinessData.availability)
        console.log(availabilityDates)
        if (availabilityDates.length == 1) {
          this.reservationClientData.date = availabilityDates[0]

          let availabilityTimes = Object.keys(this.reservationBusinessData.availability[availabilityDates[0]])
          if (availabilityTimes.length == 1) {
            this.reservationClientData.time = availabilityTimes[0]

            this.showDateTimeInOneCard = true;
          }
        }
      }
    });

    // Set today and tomorrow dates
    let today = new Date();

    let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1)

    // Change to locale date
    this.today_date = today.toLocaleString( 'sv', { timeZoneName: 'short' } ).slice(0, 10);
    this.tomorrow_date = tomorrow.toLocaleString( 'sv', { timeZoneName: 'short' } ).slice(0, 10);

    // Retrieve contact information from cookies
    // if(VueCookies.get("order_" + this.reservationBusinessData.short_url)) {
    //   try{
    //     this.order = VueCookies.get("order_" + this.reservationBusinessData.short_url);
    //   } catch(err) {
    //     VueCookies.remove("order_" + this.reservationBusinessData.short_url);
    //   }
    // }

    // Get available languages
    this.availableLanguages = ['it_it', 'en_gb'];
  },
  updated () {
    const route = useRoute();
    const category_id = route.params['category_id'];
    if(category_id) {
      this.active_food_category_id = category_id;
    } else {
      this.active_food_category_id = null;
    }
    if(this.reservationBusinessData) {
      document.title = `${this.reservationBusinessData.name} - ${this.$t('reservation_page_title')}`
    }
  },
  methods: {
    fetchReservationForm: function (url) {
      return new Promise((res, rej) => {
        axios.get(`${this.$api_hostname}/api/v1/reservation/${url}`)
        .then((response) => {
          res(response);
          this.ajax_error = false;
          this.show_loading = false;
          this.reservationBusinessData = response.data;
          if (response.data.telephone && response.data.telephone.prefix && response.data.telephone.number) {
            let formattedNumber = String(response.data.telephone.number).replace(/^(.{3})(.{3})(.*)$/, "$1 $2 $3");
            this.reservationBusinessData.telephone_readable = `(+${response.data.telephone.prefix}) ${formattedNumber}`;
            this.reservationBusinessData.telephone_link = '+' + response.data.telephone.prefix + response.data.telephone.number;
          }
        })
        .catch((err) => {
          rej(err);
          console.log("err", err.response)
          this.show_loading = false;
          if (err?.response?.status == 404) {
            this.reservationNotFoundError = true;
          } else {
            this.ajax_error = true;
          }
        });
      });
    },
    doReservation: function(event) {
      event.preventDefault();
      this.doReservationLoading = true;
      
      axios.post(`${this.$api_hostname}/api/v1/reservation/${this.reservationBusinessData.url}`, {
        date: this.reservationClientData.date,
        time: this.reservationClientData.time,
        number_of_people: this.reservationClientData.number_of_people,

        contact_name: this.reservationClientData.name,
        contact_email: this.reservationClientData.email,
        contact_telephone: {prefix: this.reservationClientData.telephone_prefix, number: this.reservationClientData.telephone_number},
        contact_notes: this.reservationClientData.notes,
        contact_marketing_consent: this.reservationClientData.marketing_consent,
      }).then((response) => {
        this.doReservationLoading = false;
        
        if (response?.data?.reserved == "ok") {
          this.$router.push({ path: `/${this.reservationBusinessData.url}/reservation/${response.data.token}` })
        } else {
          this.reservationError = true;
        }
      })
      .catch((err) => {
        this.doReservationLoading = false;
        this.reservationError = true;
        console.log(err);
      });
    },
    scrollToSection: function(section) {
      setTimeout(() => {
        document.getElementById(`section-${section}`).scrollIntoView();
      }, 20)
    },
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:500");
.btn {
  margin: .3rem;
  padding: 10px 10px;
  border-radius: 50px;
  border: 0;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px;
  cursor: pointer;
}
.btn:disabled {
  background-image: linear-gradient(147deg, #494949 0%, #727272 74%);
  box-shadow: 0px 10px 15px rgba(56, 56, 56, 0.4);
  cursor: not-allowed;
  color: #FFF;
}
.btn-orange {
  background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
  box-shadow: 0px 10px 15px rgba(252, 56, 56, 0.4);
  color: #fff;
}
.btn-orange:hover {
  color: #fff;
}
.btn-white {
  background-color: #FFF;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.4);
  color: #000;
}
.btn-blue {
  background-image: linear-gradient(147deg, #0071ce 0%, #10009e 74%);
  box-shadow: 0px 10px 15px rgba(13, 0, 133, 0.4);
  color: #fff;
}
.btn-blue:hover {
  color: #fff;
}

h5 {
  font-weight: 700;
}
</style>