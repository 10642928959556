import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const app = createApp(App)

const LOCALHOST = false
app.config.globalProperties.$api_hostname = (LOCALHOST) ? 'http://localhost:3000' : 'https://easymenu-cloud-api.herokuapp.com'

app.use(i18n)
app.use(router)
app.mount('#app')
