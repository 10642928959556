<template>
  <Header v-if="reservationBusinessData" :reservationBusinessData="reservationBusinessData" />
  <header v-else>
    <div class="p-5 text-center" v-if="show_loading">
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </header>
  <!--Main Navigation-->

  <!--Main layout-->
  <LanguageSelector />
  <main v-if="reservationBusinessData && reservationClientData">
    <ReservationSummary
      :reservationClientData="reservationClientData"
      :reservationBusinessData="reservationBusinessData" />
  </main>
  <main v-else>
    <div class="container justify-content-center p-5">
        <div class="alert alert-danger" role="alert" v-if="ajax_error">
          {{ $t("no_internet_connection") }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="reservationNotFoundError">
          {{ $t("menu_not_found") }}
      </div>
    </div>
  </main>
  <!--Main layout-->
</template>

<script>
import { useRoute } from 'vue-router'
import axios from 'axios';
import Header from '../components/Header.vue'
import ReservationSummary from '../components/ReservationSummary.vue'
import LanguageSelector from '../components/LanguageSelector.vue'

export default {
  name: 'ReservationForm',
  components: {
    Header,
    ReservationSummary,
    LanguageSelector,
  },
  data () {
    return {
      languageSelectorModalShow: false,
      availableLanguages: [],

      show_loading: true,
      ajax_error: false,
      dateNotReservableError: false,
      reservationNotFoundError: false,
      reservationError: false,

      reservationClientData: null,
      doReservationLoading: false,
      reservationSummaryShow: false,
      
      reservationBusinessData: null,

      today_date: null,
      tomorrow_date: null,
      select_another_date: false,
    }
  },
  mounted () {
    const route = useRoute();
    const reservationFormUrl = route.params['reservation_form_url'].toUpperCase();
    const reservationToken = route.params['reservation_token'];
    this.fetchReservationForm(reservationFormUrl).then(() => {
      if (this.reservationBusinessData) {
        this.fetchReservation(reservationFormUrl, reservationToken);
      }
    })

    // Get available languages
    this.availableLanguages = ['it_it', 'en_gb'];
  },
  updated () {
    const route = useRoute();
    const category_id = route.params['category_id'];
    if(category_id) {
      this.active_food_category_id = category_id;
    } else {
      this.active_food_category_id = null;
    }
    if(this.reservationBusinessData) {
      document.title = `${this.reservationBusinessData.name} - ${this.$t('reservation_page_title')}`
    }
  },
  methods: {
    fetchReservationForm: async function (url) {
      await axios.get(`${this.$api_hostname}/api/v1/reservation/${url}`)
        .then((response) => {
          this.ajax_error = false;
          this.reservationBusinessData = response.data;
          if (response.data.telephone && response.data.telephone.prefix && response.data.telephone.number) {
            let formattedNumber = String(response.data.telephone.number).replace(/^(.{3})(.{3})(.*)$/, "$1 $2 $3");
            this.reservationBusinessData.telephone_readable = `(+${response.data.telephone.prefix}) ${formattedNumber}`;
            this.reservationBusinessData.telephone_link = '+' + response.data.telephone.prefix + response.data.telephone.number;
          }
        })
        .catch((err) => {
          console.log("err", err.response)
          this.show_loading = false;
          if (err?.response?.status == 404) {
            this.reservationNotFoundError = true;
          } else {
            this.ajax_error = true;
          }
        });
    },
    fetchReservation: function (reservation_form_url, reservation_token) {
      axios.get(`${this.$api_hostname}/api/v1/reservation/${reservation_form_url}/${reservation_token}`)
        .then((response) => {
          this.ajax_error = false;
          this.show_loading = false;
          this.reservationClientData = response.data;
        })
        .catch((err) => {
          console.log("err", err.response)
          this.show_loading = false;
          if (err?.response?.status == 404) {
            this.reservationNotFoundError = true;
          } else {
            this.ajax_error = true;
          }
        });
    },
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins:500");
.btn {
  margin: .3rem;
  padding: 10px 10px;
  border-radius: 50px;
  border: 0;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-size: 15px;
  cursor: pointer;
}
.btn:disabled {
  background-image: linear-gradient(147deg, #494949 0%, #727272 74%);
  box-shadow: 0px 10px 15px rgba(56, 56, 56, 0.4);
  cursor: not-allowed;
  color: #FFF;
}
.btn-orange {
  background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
  box-shadow: 0px 10px 15px rgba(252, 56, 56, 0.4);
  color: #fff;
}
.btn-orange:hover {
  color: #fff;
}
.btn-white {
  background-color: #FFF;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.4);
  color: #000;
}
.btn-blue {
  background-image: linear-gradient(147deg, #0071ce 0%, #10009e 74%);
  box-shadow: 0px 10px 15px rgba(13, 0, 133, 0.4);
  color: #fff;
}
.btn-blue:hover {
  color: #fff;
}

h5 {
  font-weight: 700;
}
</style>