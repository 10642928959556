import { createI18n } from 'vue-i18n/index'
import VueCookies from "vue-cookies";

const fallbackLng = 'it_it';
let locale = fallbackLng;
const availableLanguages = ['it_it', 'en_gb'];
const cookieLanguage = VueCookies.get("language");
if (cookieLanguage && availableLanguages.includes(cookieLanguage)) {
  locale = cookieLanguage;
}

const i18n = createI18n({
  locale,
  fallbackLng,
  messages: {
    en_gb: {
      language_name: 'English',
      reservation_page_title: 'Reservation',
      menu_not_found: 'The link you typed is not valid. Please verify you wrote it correctly.',
      no_internet_connection: "Please check your Internet connection or contact us by phone.",
      close_button: `Close`,
      reservation_form: {
        date_title: 'Which day?',
        date_today: 'Today',
        date_tomorrow: 'Tomorrow',
        date_another_day: 'Another day',

        time_title: 'At what time?',

        number_of_people_title: 'For how many people?',
        number_of_people_n_or_more: '{n} or more',

        contact_info_title: 'Your information',
        contact_info_name: 'Name',
        contact_info_email: 'Email',
        contact_info_telephone: 'Phone',
        contact_info_notes: 'Notes',
        contact_marketing_consent: 'I would like to receive updates and special offers by subscribing to the newsletter.',

        reserve_button: 'Book',
        privacy_policy: `By clicking the button to submit this form, you authorise the processing of your personal data in accordance with Article 13 GDPR 679/2016 and the <a href="https://easymenu.cloud/politica-privacy/" target="_blank"><span class="text-black-50">Privacy Policy</span></a>.`,
        
        no_availability_error: 'Please contact us by phone to book.',
        date_not_reservable_error_contact_us: 'Please contact us by phone to reserve for another date.',
        date_not_reservable_error_no_contact: 'We are sorry, at the moment it\'s not possible to reserve for another date.',
        too_many_people_error: 'Please contact us by phone to book for {n} or more people.',
        generic_error: 'An error has occurred.<br />Please contact us by phone.',
      },
      reservation_summary: {
        thank_you_text: `Thanks for the reservation!<br />
        The reservation if confirmed. We sent you an email with the information.`,
        in_case_of_problems_text: 'In case of problems please contact us by phone.',

        title: 'Reservation summary',
        date: 'Date',
        time: 'Time',
        number_of_people: 'Number of people',
        name: 'Name',
        email: 'Email',
        telephone: 'Phone number',
      },
    },
    it_it: {
      language_name: 'Italiano',
      reservation_page_title: 'Prenotazione',
      menu_not_found: 'Il link non è valido. Si prega di verificare di averlo scritto correttamente.',
      no_internet_connection: 'Si prega di verificare la connessione a Internet o di contattarci telefonicamente.',
      close_button: `Chiudi`,
      reservation_form: {
        date_title: 'Che giorno?',
        date_today: 'Oggi',
        date_tomorrow: 'Domani',
        date_another_day: 'Un altro giorno',

        time_title: 'A che ora?',

        number_of_people_title: 'Per quante persone?',
        number_of_people_n_or_more: '{n} o più',

        contact_info_title: 'Qualche tua informazione',
        contact_info_name: 'Nome',
        contact_info_email: 'Email',
        contact_info_telephone: 'Telefono',
        contact_info_notes: 'Note',
        contact_marketing_consent: 'Mi piacerebbe ricevere aggiornamenti e offerte speciali iscrivendomi alla newsletter.',

        reserve_button: 'Prenota',
        privacy_policy: `Cliccando il pulsante per inviare questo modulo, autorizzi il trattamento dei tuoi dati personali in base all'art. 13 GDPR 679/2016 e alla <a href="https://easymenu.cloud/politica-privacy/" target="_blank"><span class="text-black-50">Privacy Policy</span></a>.`,
        
        no_availability_error: 'Vi preghiamo di contattarci telefonicamente per prenotare.',
        date_not_reservable_error_contact_us: 'Vi preghiamo di contattarci telefonicamente per prenotare per altre date.',
        date_not_reservable_error_no_contact: 'Siamo spiacenti, al momento non è possibile prenotare per altre date.',
        too_many_people_error: 'Vi preghiamo di contattarci telefonicamente per prenotare per {n} o più persone.',
        generic_error: 'Si è verificato un errore.<br />Vi preghiamo di contattarci telefonicamente.',
      },
      reservation_summary: {
        thank_you_text: `Grazie per aver prenotato!<br />
        La prenotazione è confermata. Le abbiamo inviato una email con le informazioni.`,
        in_case_of_problems_text: 'In caso di problemi la preghiamo di contattarci telefonicamente.',

        title: 'Riepilogo prenotazione',
        date: 'Data',
        time: 'Orario',
        number_of_people: 'Numero persone',
        name: 'Nominativo',
        email: 'Email',
        telephone: 'Telefono',
      },
    },
  }
})

export default i18n;