<template>
  <header v-if="reservationBusinessData">
    <div class="card shadow border-0 text-white" style="background-color: #FFF; border-radius:0">
      <img
        v-if="reservationBusinessData.menu_image"
        :src="$static_hostname + '/images/' + reservationBusinessData.menu_image"
        class="card-img"
        style="max-height:30vh;position:cover;object-fit:cover"
      />
      <div class="bg-transparent text-center p-4" :class="(reservationBusinessData.menu_image) ? 'card-img-overlay' : ''">
        <h1 class="card-title text-dark">{{ $t('reservation_page_title') }}<br />
        {{ reservationBusinessData.name }}</h1>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: ['reservationBusinessData'],
}
</script>